"use client";
import { createContext, Dispatch, SetStateAction } from "react";
type HeaderContextType = {
  isFixedHeader: boolean;
  setFixedHeader: Dispatch<SetStateAction<boolean>>;
};
const HeaderContextDefaultValue: HeaderContextType = {
  isFixedHeader: true,
  setFixedHeader: (_state) => {}
};
export const HeaderContext = createContext(HeaderContextDefaultValue);
