"use client";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@hooks/reduxHooks";
import { usePathname } from "next/navigation";
import { useTheme } from "next-themes";
import { useSession } from "next-auth/react";
import { useOnlineStatusMutation } from "graqhql-gen/graphql";
import { RootState } from "store";
import { setUserInfo } from "@slices/UserSlice";
import Header from "./Header";
const pagesWithHeader = [
  "notification",
  "posts",
  "chat",
  "profile",
  "friends",
  "savedposts",
  "post"
];
const HeaderWrapper = () => {
  const page = usePathname();
  const { data: session, status } = useSession();
  const user = useAppSelector((state: RootState) => state.users.user);
  const { setTheme } = useTheme();

  const dispatch = useAppDispatch();
  const [onlineStatus] = useOnlineStatusMutation();

  useEffect(() => {
    if (status === "authenticated" && session?.user && !user.setting) {
      const newSession = {
        ...session.user,
        profileImage: session.user.profileImage,
        email: session?.user?.email ?? "",
        id: session?.user?.id ?? ""
      };

      if (
        newSession?.email !== "" &&
        newSession?.id !== "" &&
        newSession?.profileImage !== ""
      )
        dispatch(setUserInfo(newSession));
      setTheme(session?.user?.setting?.theme ?? "system");
      onlineStatus({
        variables: {
          userId: session?.user?.id,
          status: "online"
        }
      })
        .then(() => {})
        .catch((e) => {});
    }
  }, [session, status]);
  // use effect to set user online status every 30 seconds

  useEffect(() => {
    if (!session?.user?.id) return;
    const interval = setInterval(() => {
      onlineStatus({
        variables: {
          userId: session?.user?.id,
          status: "online"
        }
      })
        .then(() => {})
        .catch((e) => {});
    }, 3000);
    return () => clearInterval(interval);
  }, [session]);
  return (
    <>
      {(page === "/" || pagesWithHeader.includes(page.split("/")[1])) && (
        <Header onPage={page === "/" ? "Home" : page.split("/")[1]} />
      )}
    </>
  );
};

export default HeaderWrapper;
