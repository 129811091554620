import { createObjectURL } from "blob-util";
import { PostMedia } from "graqhql-gen/graphql";
import { Dispatch, SetStateAction } from "react";

export const downloadImageUrl = async (imageUrl: string) => {
  try {
    if (!imageUrl) return "";
    else {
      const image = await fetch(imageUrl);
      const imageBlob = await image.blob();
      return createObjectURL(imageBlob);
    }
  } catch (error) {
    console.log("Error loading image: ", error);
    return "";
  }
};

export const imageToBlobUrl = (
  imageFile: File,
  setAttachments: Dispatch<SetStateAction<PostMedia[]>>
) => {
  const preview = document.createElement("img");
  const reader = new FileReader();
  reader.addEventListener("load", () => {
    if (imageFile.type && reader.result) {
      typeof reader.result === "string" &&
        setAttachments((oldAttachments) => [
          ...oldAttachments,
          {
            mediaType: imageFile.type,
            sourceUri: reader.result as string
          }
        ]);
    }
  });

  imageFile && reader.readAsDataURL(imageFile);
};

export const validateImageUrl = async (url: string) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const timeoutId = setTimeout(() => controller.abort(), 2000);
  try {
    const response = await fetch(url, { signal });
    clearTimeout(timeoutId);

    // Check if the response is okay (status code 200-299)
    if (!response.ok) return false;

    // Check the Content-Type header for an image MIME type
    const contentType = response.headers.get("Content-Type");
    return contentType && contentType.startsWith("image/");
  } catch (error) {
    return false;
  }
};
