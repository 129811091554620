import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { downloadImageUrl } from "@utils/imageHelper";
import { Post, User } from "graqhql-gen/graphql";
export const loadProfileImageAsync = createAsyncThunk(
  "loadProfileImage",
  downloadImageUrl
);

interface UserState {
  user: User & { image?: string };
  status: "idle" | "loading" | "failed";
}
const initialState: UserState = {
  user: {
    id: "",
    email: "",
    username: "",
    friendList: [],
    listNotification: [],
    savedPosts: [],
    profileImage: "",
    provider: ""
  },
  status: "loading" // loading state
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeProfileImage: (state, action: PayloadAction<string>) => {
      if (state?.user?.profileImage) state.user.profileImage = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<User>) => {
      if (state?.user) state.user = action.payload;
    },
    toggleSavePost: (state, action: PayloadAction<Post[]>) => {
      if (state?.user?.savedPosts) state.user.savedPosts = action.payload;
    },
    changeUserSettings: (state, action: PayloadAction<User["setting"]>) => {
      if (state?.user) state.user.setting = action.payload;
    }
  },
  extraReducers: (builder) => {
    // handle async actions: pending, fulfilled, rejected (for errors)
    builder.addCase(loadProfileImageAsync.pending, (state) => {
      state.status = "loading";
    }),
      builder.addCase(loadProfileImageAsync.fulfilled, (state, action) => {
        if (state.user) state.user.profileImage = action.payload;
        state.status = "idle";
      }),
      builder.addCase(loadProfileImageAsync.rejected, (state) => {
        state.status = "failed";
      });
  }
});
export const {
  changeProfileImage,
  setUserInfo,
  toggleSavePost,
  changeUserSettings
} = userSlice.actions;

export default userSlice.reducer;
