"use client";
import { ScrollContext } from "@contexts/ScrollContext";
import { HeaderContext } from "@contexts/toggleFixedHeader";
import LinearProgress from "@mui/material/LinearProgress";
import { useMediaQuery } from "@mantine/hooks";
import { motion } from "framer-motion";
import { User } from "graqhql-gen/graphql";
import Link from "next/link";
import { useRouter } from "next/navigation";
import PubSub from "@libs/PubSub";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { RiListSettingsLine } from "@react-icons/all-files/ri/RiListSettingsLine";
const Setting = dynamic(() => import("../../Setting/Setting"), { ssr: false });
import headerStyle from "./Header.module.css";
import dynamic from "next/dynamic";
import UserSearch from "../UserSearch";
import CustomImage from "../CustomImage";
import { useSession } from "next-auth/react";
import { useTheme } from "next-themes";
import { useAppDispatch, useAppSelector } from "@hooks/reduxHooks";
import { useOnlineStatusMutation } from "graqhql-gen/graphql";
import { setUserInfo } from "slices/UserSlice";
import Skeleton from "@mui/material/Skeleton";
const HeaderMenu = dynamic(() => import("./HeaderMenu/HeaderMenu"), {
  ssr: false
});
interface HeaderProps {
  onPage: string;
  isLoading?: boolean;
}
const Header = ({ onPage, isLoading }: HeaderProps) => {
  const [currentPage, setCurrentPage] = useState<string>();
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [displayEditor, setDisplayEditor] = useState<boolean>(false);
  const [isDisplayMenuIcon, setIsDisplayMenuIcon] = useState<boolean>(true);
  const { isFixedHeader } = useContext(HeaderContext);
  const mobileView = useMediaQuery("(max-width: 740px)");
  const buttonRef = useRef(null);
  const [isOpenSetting, setIsOpenSetting] = useState<boolean>(false);
  const isScrollUp = useContext(ScrollContext);
  const router = useRouter();
  const { data: session, status } = useSession();
  const { setTheme } = useTheme();

  const dispatch = useAppDispatch();
  const [onlineStatus] = useOnlineStatusMutation();

  useEffect(() => {
    if (status === "authenticated" && session?.user && !currentUser?.setting) {
      const newSession = {
        ...session.user,
        profileImage: session?.user?.profileImage || session?.user?.image,
        email: session?.user?.email ?? "",
        id: session?.user?.id ?? ""
      };

      if (
        newSession?.email !== "" &&
        newSession?.id !== "" &&
        newSession?.profileImage !== ""
      )
        dispatch(setUserInfo(newSession));
      setTheme(session?.user?.setting?.theme ?? "system");
      onlineStatus({
        variables: {
          userId: session?.user?.id,
          status: "online"
        }
      })
        .then(() => {})
        .catch((e) => {});
    }
  }, [session]);

  const currentUser = useAppSelector((state) => state.users.user);

  useEffect(() => {
    setCurrentPage(onPage);
    closeUserPopover();
  }, [onPage]);

  const closeSetting = useCallback((): void => {
    setIsOpenSetting(false);
  }, []);

  const openUserPopover = () => {
    setUserAnchorEl(buttonRef.current);
  };
  const closeUserPopover = () => {
    setUserAnchorEl(null);
  };
  useEffect(() => {
    const token = PubSub.subscribe("DISPLAY_EDITOR", (message, data) => {
      setDisplayEditor(data);
    });
    return () => {
      PubSub.unsubscribe(token);
    };
  }, []);

  return (
    <motion.div
      className={`${headerStyle.headerContainer} sticky top-0 z-[1000] bg-gradient-to-r from-[#227ff2] to-[#6754f7]  dark:from-[#213048] dark:to-[#182c37] duration-200`}
      animate={
        (onPage.split("/")[0] === "profile" || !isScrollUp || displayEditor) &&
        !isFixedHeader
          ? { y: -100 }
          : { y: 0 }
      }
      transition={{ duration: 0.2 }}
    >
      {isLoading && (
        <div className="absolute w-full -bottom-1">
          <LinearProgress
            className="z-50 w-full "
            sx={{
              height: "4px",
              zIndex: 50,
              backgroundColor: "rgb(67 56 202)",

              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "rgb(199 210 254)"
              }
            }}
          />
        </div>
      )}
      <div
        className={`${headerStyle.mediumContainer} xl:w-[90%] w-[95%] m-auto header-wrapper flex justify-between items-center duration-150`}
      >
        <div className="flex items-center justify-start lg:gap-0 md:gap-[5%] gap-0 2xl:basis-[75%] grow 2xl:grow-0 duration-150">
          <Link
            href="/"
            className="xl:basis-[33.5%] lg:basis-[40%] basis-[10%]"
          >
            <div
              className={
                "flex items-center justify-start xl:basis-[33.5%] lg:basis-[40%] basis-[10%] pointer-events-auto cursor-pointer duration-150"
              }
            >
              <div
                className={`${headerStyle.logoContainer} relative logo-container duration-150 md:h-[80px] scale-110 md:w-[80px] w-[60px] h-[60px]`}
              >
                <CustomImage
                  src="socialground-nobg.png"
                  className="absolute object-cover non-selectable"
                  fill
                  sizes="(max-width: 640px) 60px, 80px"
                  alt="the image"
                  priority
                />
              </div>
              {!mobileView && (
                <h1
                  className={`font-bold text-[calc(1.5vw+10px)] ml-1 non-selectable text-white dark:text-white`}
                >
                  Social Ground
                </h1>
              )}
            </div>
          </Link>
          <div
            className={`${headerStyle.searchInputWrapper} flex justify-center md:px-0 sm:px-3 px-0 gap-2 items-center grow duration-150`}
          >
            <UserSearch
              isInHeader
              onPage={onPage}
              setExtendSearchInputLength={setIsDisplayMenuIcon}
              isExtendSearchInputLength={isDisplayMenuIcon}
              selectUserHandler={(user: User) => {
                router.push(
                  `/profile/${currentUser?.id === user?.id ? "" : user.id}`,
                  undefined
                );
              }}
            />

            {mobileView && isDisplayMenuIcon && (
              <div className="container flex items-center w-full justify-evenly">
                <div
                  className={`${headerStyle.textVerticalBar} vertical-bar rounded-lg bg-white md:block hidden`}
                ></div>
                <ul className="flex items-center justify-evenly basis-[80%]">
                  <Link href="/">
                    <li
                      onClick={() => setCurrentPage("Home")}
                      className={`${
                        headerStyle.navigateItemWrapper
                      } fill-slate-100 ease-in duration-100 my-3 py-3 relative ${
                        currentPage === "Home" &&
                        "bg-[#E5E8FF] dark:bg-[#374151] dark:!fill-[rgb(0,238,255)] !fill-[blue] md:px-[2vw] px-[0.05vw]"
                      }  dark:fill-white cursor-pointer rounded-lg`}
                    >
                      <div
                        className={`${headerStyle.navigateItemContainer} flex items-center relative dark:text-white`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`${
                            headerStyle.utilityButtons
                          } mx-3 bi bi-house-door ${
                            currentPage === "Home"
                              ? "sm:w-[32px] w-[28px]"
                              : "sm:w-[30px] w-[26px]"
                          }`}
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
                        </svg>
                      </div>
                      <div
                        className={`${headerStyle.navigateHoverIndicator} absolute left-0 top-0`}
                      ></div>
                    </li>
                  </Link>
                  <Link href="/chat">
                    <li
                      onClick={() => setCurrentPage("chat")}
                      className={`${
                        headerStyle.navigateItemWrapper
                      } fill-slate-100  ease-in duration-100 my-3 py-3 relative ${
                        currentPage === "chat" &&
                        "bg-[#E5E8FF] dark:bg-[#374151] dark:!fill-[rgb(0,238,255)] !fill-[blue] md:px-[2vw] px-[0.05vw]"
                      } dark:fill-white cursor-pointer rounded-lg`}
                    >
                      <div
                        className={`${headerStyle.navigateItemContainer} flex items-center`}
                      >
                        <div className="relative iconHeaderWrapper ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`${
                              headerStyle.utilityButtons
                            } mx-3 bi bi-chat-text ${
                              currentPage === "chat"
                                ? "sm:w-[32px] w-[28px]"
                                : "sm:w-[30px] w-[26px]"
                            }`}
                            viewBox="0 0 16 16"
                          >
                            <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                            <path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8zm0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z" />
                          </svg>
                          <div
                            className={`${headerStyle.count_notification} flex justify-center items-center absolute rounded-xl px-1 top-0 right-0 w-full`}
                          >
                            <p className="text-white text-[85%] bg-[red] px-1 rounded-xl ">
                              99+
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${headerStyle.navigateHoverIndicator} absolute left-0 top-0`}
                      ></div>
                    </li>
                  </Link>
                  <Link href="/notification">
                    <li
                      onClick={() => setCurrentPage("notification")}
                      className={`${
                        headerStyle.navigateItemWrapper
                      } fill-slate-100  ease-in duration-100 my-3 py-3 relative ${
                        currentPage === "notification" &&
                        "bg-[#E5E8FF] dark:bg-[#374151] dark:!fill-[rgb(0,238,255)] !fill-[blue] md:px-[2vw] px-[0.05vw]"
                      } dark:fill-white cursor-pointer rounded-lg`}
                    >
                      <div
                        className={`${headerStyle.navigateItemContainer} flex items-center`}
                      >
                        <div className="relative iconHeaderWrapper">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`${
                              headerStyle.utilityButtons
                            } mx-3 bi bi-bell ${
                              currentPage === "notification"
                                ? "sm:w-[32px] w-[28px]"
                                : "sm:w-[30px] w-[26px]"
                            }`}
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                          </svg>
                          <div
                            className={`${headerStyle.count_notification} flex justify-center items-center absolute rounded-xl px-1 top-0 right-0 w-full`}
                          >
                            <p className="text-white text-[85%] bg-[red] px-1 rounded-xl ">
                              99+
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${headerStyle.navigateHoverIndicator} absolute left-0 top-0`}
                      ></div>
                    </li>
                  </Link>
                  <Link href="story">
                    <li
                      onClick={() => setCurrentPage("story")}
                      className={`${
                        headerStyle.navigateItemWrapper
                      } fill-slate-100 ease-in duration-100 my-3 py-3 ${
                        currentPage === "story" &&
                        "bg-[#E5E8FF] dark:bg-[#374151] dark:!fill-[rgb(0,238,255)] !fill-[blue] px-[10%]"
                      } dark:fill-white relative cursor-pointer`}
                    >
                      <div
                        className={`${headerStyle.navigateItemContainer} flex items-center`}
                      >
                        <div className="relative iconHeaderWrapper">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`${
                              headerStyle.utilityButtons
                            } mx-3 bi bi-caret-right-square ${
                              currentPage === "story"
                                ? "sm:w-[32px] w-[28px]"
                                : "sm:w-[30px] w-[26px]"
                            }`}
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path d="M5.795 12.456A.5.5 0 0 1 5.5 12V4a.5.5 0 0 1 .832-.374l4.5 4a.5.5 0 0 1 0 .748l-4.5 4a.5.5 0 0 1-.537.082z" />
                          </svg>
                          <div
                            className={`${headerStyle.count_notification} flex justify-center items-center absolute rounded-xl px-1 top-0 w-full right-0`}
                          >
                            <p className="text-white text-[85%] bg-[red] px-1 rounded-xl ">
                              99+
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${headerStyle.navigateHoverIndicator} absolute left-0 top-0`}
                      ></div>
                    </li>
                  </Link>
                </ul>
              </div>
            )}
          </div>
        </div>
        {isOpenSetting && (
          <Setting isOpenSetting={isOpenSetting} closeSetting={closeSetting} />
        )}
        <div className="flex items-center justify-end justify-self-end">
          <button
            onClick={openUserPopover}
            ref={buttonRef}
            className={`${headerStyle.userProfileImageButton} border border-[#E5FFFC] dark:border-none dark:hover:bg-[#7070FF] dark:bg-[#4949DF] flex items-center justify-center rounded-full lg:p-1.5 duration-150`}
            aria-label="User profile menu"
          >
            {status === "loading" ? (
              <div
                className={
                  "rounded-[50px] bg-white hover:bg-[#f5f5f5] flex items-center justify-start profile-left-sidebar cursor-pointer dark:drop-shadow-[0px_5px_10px_0px_rgba(0,255,255,0.7)]"
                }
              >
                <Skeleton
                  variant="circular"
                  animation="wave"
                  width={45}
                  height={45}
                />
              </div>
            ) : (
              <div className="duration-150 cursor-pointer relative md:h-[45px] md:w-[45px] w-[45px] h-[45px]">
                <CustomImage
                  src={
                    (currentUser?.profileImage || currentUser?.image) ??
                    "default"
                  }
                  className={`${headerStyle.userProfileImage} object-cover non-selectable rounded-full pointer-events-none absolute`}
                  sizes="(max-width: 640px) 45px, 45px"
                  fill
                  external={
                    currentUser?.profileImage || currentUser?.image
                      ? currentUser?.provider !== "customCredentials"
                      : false
                  }
                  alt="User image"
                />
              </div>
            )}
            {!mobileView && (
              <RiListSettingsLine
                size={30}
                className={`${headerStyle.menuIcon} ml-2 text-[#E5FFFC] dark:text-white`}
              />
            )}
          </button>

          <HeaderMenu
            setIsOpenSetting={setIsOpenSetting}
            userAnchorEl={userAnchorEl}
            closeUserPopover={closeUserPopover}
          />
        </div>
      </div>
    </motion.div>
  );
};
export default Header;
