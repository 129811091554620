import { KeyboardShortcutContext } from "@contexts/KeyboardShortcutContext";
import { useMediaQuery } from "@mantine/hooks";
import { AnimatePresence } from "framer-motion";
import { User, useSearchUserByNameLazyQuery } from "graqhql-gen/graphql";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
const UserSearchResultPanel = dynamic(
  () => import("./UserSearchResultPanel/UserSearchResultPanel"),
  { ssr: false }
);
import dynamic from "next/dynamic";
import { debounce } from "es-toolkit";
interface Props {
  isInHeader?: boolean;
  onPage?: string;
  setExtendSearchInputLength?: Dispatch<SetStateAction<boolean>>;
  isExtendSearchInputLength?: boolean;
  selectUserHandler: (user: User) => void;
  selectedMembers?: User[];
  setIsSearching?: Dispatch<SetStateAction<boolean>>;
  selectedMemberError?:
    | Merge<
        FieldError,
        (FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined)[]
      >
    | undefined;
}
const UserSearch: FC<Props> = ({
  isInHeader,
  onPage,
  setExtendSearchInputLength,
  isExtendSearchInputLength,
  selectUserHandler,
  setIsSearching,
  selectedMemberError,
  selectedMembers
}: Props) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchInputWrapperRef = useRef<HTMLDivElement>(null);
  const mobileView = useMediaQuery("(max-width: 740px)");
  const { keys } = useContext(KeyboardShortcutContext);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<User[]>([]);
  const [openSearchDialog, setOpenSearchDialog] = useState<boolean>(false);
  const [searchIconColor, setSearchIconColor] = useState("black");
  const [searchInput, setSearchInput] = useState<string>("");
  useEffect(() => {
    for (const items of keys.keys()) {
      if (items === "/") {
        searchInputRef.current?.focus();
      }
    }
  }, [keys]);
  useEffect(() => {
    setSearchInput("");
  }, [onPage]);
  useEffect(() => {
    if (isInHeader)
      if (!mobileView) {
        if (searchInputRef?.current) {
          searchInputRef.current.style.maxWidth = "100%";
        }
        if (searchInputWrapperRef?.current) {
          searchInputWrapperRef.current.style.width = "100%";
        }
      } else {
        if (searchInputRef.current) {
          searchInputRef.current.style.maxWidth = "43px";
          searchInputRef.current.blur();
          closeSearchDialog();
        }
        if (searchInputWrapperRef?.current) {
          searchInputWrapperRef.current.style.width = "43px";
        }
      }
  }, [mobileView]);

  const toggleDisplayMenuIcons = () => {
    if (isInHeader) {
      if (searchInputRef && searchInputWrapperRef) {
        if (
          searchInputRef.current &&
          searchInputWrapperRef.current &&
          mobileView
        ) {
          if (!isExtendSearchInputLength) {
            if (searchInputRef.current.value === "") {
              searchInputRef.current.style.maxWidth = "43px";
              searchInputWrapperRef.current.style.width = "auto";
              searchInputRef.current.blur();
              closeSearchDialog();
            }
          } else {
            searchInputRef.current.focus();
            searchInputRef.current.style.maxWidth = "100%";
            searchInputWrapperRef.current.style.width = "100%";
          }
        }
      }
      if (
        searchInputRef.current &&
        searchInputRef.current.value === "" &&
        mobileView
      )
        setExtendSearchInputLength &&
          setExtendSearchInputLength((oldData: boolean) => !oldData);
    }
  };
  const [
    searchUserByNameQuery,
    {
      data: searchUserResultData,
      loading,
      networkStatus,
      fetchMore,
      error: searchUserError,
      refetch
    }
  ] = useSearchUserByNameLazyQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (data?.searchUserByName) {
        setSearchResult([...searchResult, ...data.searchUserByName]);
      } else {
        setSearchResult([]);
      }
    }
  });
  const debounceSearchUserByName = useCallback(
    debounce(searchUserByNameQuery, 120),
    []
  );
  const onSearchInput = async (e: React.FormEvent<HTMLInputElement>) => {
    setSearchInput(e.currentTarget.value);
    setOpenSearchDialog(true);
    setSearchResult([]);
    if (e.currentTarget.value !== "") {
      debounceSearchUserByName({
        variables: {
          username: e.currentTarget.value,
          limit: 6,
          cursorIndex: searchResult[searchResult.length - 1]?.id ?? ""
        }
      });
    }
  };

  const deleteSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
      setSearchInput("");
    }
  };
  const closeSearchDialog = () => {
    setIsSearching && setIsSearching(false);
    setOpenSearchDialog(false);
    setSearchIconColor("black");
  };
  return (
    <div
      className={`relative cursor-pointer w-full`}
      onClick={toggleDisplayMenuIcons}
      ref={searchInputWrapperRef}
    >
      <input
        className={`pl-10 py-[0.7rem] ${
          selectedMemberError ? "border-red-400" : "border-white"
        } text-xl text-blue-700 lg:pr-12 pr-2 w-full rounded-[30px] bg-white border-2  focus:border-indigo-500 duration-300`}
        type="search"
        placeholder="[ / ] Search for something"
        onChange={onSearchInput}
        value={searchInput}
        onFocus={() => {
          setSearchIconColor("rgb(59 130 246)");
          setIsSearching && setIsSearching(true);
        }}
        onBlur={closeSearchDialog}
        ref={searchInputRef}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill={searchIconColor}
        className="absolute left-0 top-0 ml-3.5 mt-4 cursor-pointer bi bi-search"
        viewBox="0 0 16 16"
      >
        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
      </svg>
      {searchInput !== "" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          fill="blue"
          className="absolute top-0 right-0 pr-3 mt-2 cursor-pointer bi bi-x-circle"
          viewBox="0 0 16 16"
          onClick={deleteSearchInput}
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      )}
      <AnimatePresence>
        {(openSearchDialog || !isInHeader) && (
          <UserSearchResultPanel
            searchUserError={searchUserError}
            isInHeader={isInHeader}
            loading={loading}
            searchUserResultData={searchUserResultData}
            networkStatus={networkStatus}
            selectedMembers={selectedMembers}
            searchInput={searchInput}
            searchUserByNameQuery={searchUserByNameQuery}
            searchResult={searchResult}
            selectUserHandler={selectUserHandler}
            selectedMemberError={selectedMemberError}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserSearch;
