"use client";
import { HeaderScrollProvider } from "@hooks/UseHeaderScroll";
import { KeyboardShortcutProvider } from "@hooks/UseKeyboardShortcut";
import { SessionProvider } from "next-auth/react";
import { ApolloWrapper } from "@libs/ApolloClientWrapper";
import { ThemeProvider } from "next-themes";
import { Provider } from "react-redux";
import { AppStore, store } from "store";
import { useMemo, useRef } from "react";
import { Session } from "next-auth";
export default function ClientProviders({
  children,
  session,
  sessionKey
}: {
  children: React.ReactNode;
  session: Session | null;
  sessionKey: number;
}) {
  const storeRef = useRef<AppStore>();
  if (!storeRef.current) {
    // Create the store instance the first time this renders
    storeRef.current = store();
  }
  const memoizedSessionKey = useMemo(() => {
    return sessionKey;
  }, [session]);

  return (
    <SessionProvider refetchOnWindowFocus={true} key={memoizedSessionKey}>
      <ApolloWrapper>
        <Provider store={storeRef.current}>
          <KeyboardShortcutProvider>
            <HeaderScrollProvider>
              <ThemeProvider attribute="class">{children}</ThemeProvider>
            </HeaderScrollProvider>
          </KeyboardShortcutProvider>
        </Provider>
      </ApolloWrapper>
    </SessionProvider>
  );
}
