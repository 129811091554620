import { configureStore } from "@reduxjs/toolkit";
import postReducer from "./slices/PostSlice";
import userReducer from "./slices/UserSlice";
import chatReducer from "./slices/ChatSlice";

export const store = () => {
  return configureStore({
    reducer: {
      posts: postReducer,
      users: userReducer,
      chat: chatReducer
    }
  });
};

// Infer the type of store
export type AppStore = ReturnType<typeof store>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
